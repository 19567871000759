import React from 'react'
import Nav from './Nav'
const Landing = ()=>{
    return(
        <div>
            <Nav/>
            <div className = 'landing'>
                <div className = 'section'>
                    <div className="main">
                        <div className="boxes one">
                            <p>Hi ...</p>
                        </div>
                        <div className="boxes two">
                            <p>How are u doing</p>
                        </div>
                        <div className="boxes three">
                            <p>great, hw abt u</p>
                        </div>
                        <div className="boxes four">
                            <p>fine, it is pleasure to meet you</p>
                        </div>
                        <div className="boxes five">
                            <p>you too</p>
                        </div>
                        <div className="boxes six">
                            <p>have a nice day</p>
                        </div>
                    </div>

                </div>
            <div>
            
        </div>
            
            </div>
        </div>
        
    )
}

export default Landing