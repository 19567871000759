import Axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { apiUrl } from "../../Utils/api";
const Message = ({ conversation, currentUser, value, data, online }) => {
  const [navDetails, setNavDetails] = useState(null);
  const [name, setName] = useState(null);
  const [profilePic, setProfilePic] = useState(null);
  const [room, setRoom] = useState(null);
  const [present, setPresent] = useState(false);
  const [conItem, setConItem] = useState("");
  // const [chatfriend, setChatfriend] = useState([])
  const [counter, setCounter] = useState("");

  const allChats = useSelector((state) => state.chats.AllChats);
  const [userMsgs] = useSelector((state) => state.UserMsgs.msgs);
  useEffect(() => {
    let perOnline = online.find((m) => m.userId !== currentUser._id);
    setRoom(perOnline);
  }, [online, currentUser._id]);

  useEffect(() => {
    if (room && navDetails && room.userId === navDetails._id) {
      setPresent(true);
    } else {
      setPresent(false);
    }
  }, [room, navDetails, online]);

  // console.log(oldMsg);
  //
  // if(currentMsg.length ===0){
  //     console.log('No data');
  // }else{
  //    console.log(currentMsg);
  // }

  useEffect(() => {
    // let controller = new AbortController();
    const ProfileNavContent = async () => {
      try {
        if (data && value) {
          setName(value.username);
          setProfilePic(value.profilePicture);
        } else if (!data && !value) {
          const friendId =
            conversation &&
            conversation.members.find((m) => m !== currentUser._id);
          //   console.log(friendId);
          if (friendId) {
            const res = await Axios.get(apiUrl + "/user/" + friendId);
            setNavDetails(res.data);
            setName(res.data.username);
            setProfilePic(res.data.profilePicture);
            // controller = null;
          }
        }
      } catch (err) {
        console.log(err);
      }
    };
    ProfileNavContent();
    // return () => controller?.abort();
  }, [data, value, conversation]);

  useEffect(() => {
    // let controller = new AbortController();
    const getMessages = async () => {
      try {
        if (conversation) {
          const res = await Axios.get(
            apiUrl + "/user/messages/" + conversation._id
          );
          //   if (res.data.length > 0) {
          //     let filtedItems = res.data.filter((item) => {
          //       return item.delAll == false;
          //     });
          //     let lastItem = filtedItems[filtedItems.length - 1];
          //     setConItem(lastItem.text);
          //     if (lastItem.sender !== currentUser._id) {
          //       setCounter(lastItem.count);
          //     }

          //     // controller = null;
          //   }
          if (res.data.length > 0) {
            let filtedItems = res.data.filter((item) => {
              return item.read == false;
            });
            let lastItem = filtedItems[filtedItems.length - 1];
            let lastCount = filtedItems.length;
            // setConItem(lastItem);
            if (lastItem.sender !== currentUser._id) {
              setCounter(lastCount);
            }

            // controller = null;
          }
        }
      } catch (err) {
        console.log(err);
      }
    };
    getMessages();
    // return () => controller?.abort();
  }, [userMsgs]);

  const image =
    "https://media.defense.gov/2020/Oct/25/2002523049/-1/-1/0/201025-M-AB981-003.JPG";
  return (
    <div>
      {data ? (
        <div>
          {!value ? (
            <p className="notFound">No such user found!</p>
          ) : (
            <div className="Users">
              <object
                className="UserPic"
                data={profilePic !== "" ? profilePic : image}
                type="image/jpg"
              >
                <img className="UserPic" src={image} />
              </object>
              <p className="Usertext">{name}</p>
            </div>
          )}
        </div>
      ) : (
        <div className="Members">
          <div className="Users">
            <div>
              <object
                className="UserPic"
                data={profilePic !== "" ? profilePic : image}
                type="image/jpg"
              >
                <img className="UserPic" src={image} />
              </object>
              <div className="navname">
                <p className="Usertext">{name}</p>
                {present ? <div className="online"></div> : <p></p>}
              </div>
            </div>
            <div className="lastMsg">
              {/* <p >{conItem}</p> */}
              <p>
                {conItem.length > 26
                  ? conItem.substring(0, 24) + " ..."
                  : conItem}
              </p>
              <p>{counter !== 0 ? counter : ""}</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Message;
