import React, { useState } from 'react';
import axios from 'axios'
import Nav from '../Nav'
import './sign.css';
import { Link, useHistory} from 'react-router-dom';
import { setUserSession} from '../Utils/Common';
import {apiUrl} from '../Utils/api'
const Login = () => {
  //apiUrl+ /
  // console.log(getToken());
    const history = useHistory();
    // const [message, setMessage] = useState('')
    const [username, setUsername] = useState('')
    const [password, setpassword] = useState('')
    const [err, setErr] = useState('')
    const [load, setLoad] = useState(false)

    const handleLogin = (e)=>{
        setLoad(true)
        e.preventDefault()
        axios({
        method: "POST",
        data: {
        username: username,
        password: password,
      },
      withCredentials: true,
      url: apiUrl+"/login",
        })
        .then((res)=>{
            setUsername('')
            setpassword('')
            setLoad(false)
          if(!res.data.success){
            console.log(res.data.error);
            
            setErr(res.data.error)
            
          }else{
            setUserSession(res.data.token, res.data.person);
            history.push(res.data.redirectUrl)
            // window.location.reload();
          }
        }).catch(error => {
          console.error(error)
          
          setErr("Network Error")
          setLoad(false)
        });
    }
    // console.log(getToken());
    
    return ( 
    <div>
      <Nav/>
        <div className = "holder">
        <div className="login-box">
            <h2>Login</h2>
            <h2 className = "err">{err}</h2>
            <form onSubmit = {handleLogin}>
                <div className="user-box">
                    <input 
                    type="text" 
                    name="username" 
                    autoComplete = "username"
                    required=""
                    value = {username}
                    onChange = {(e)=>{setUsername(e.target.value)}}
                    />
                    <label>Username</label>
                </div>
                <div className="user-box">
                    <input 
                    type="password" 
                    name="password" 
                    autoComplete = "current-password"
                    required=""
                    value = {password}
                    onChange = {(e)=>{setpassword(e.target.value)}}
                    />
                    <label>Password</label>
                </div>
                {!load?
                <a href="#">
                  <button>Submit</button>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                </a>
                :
                <button className='loading'>Loading...</button>
              }
                  
            </form>
            {err && <Link to = "/user/forgot" className = 'ResetLink'>Reset password</Link>}
        </div>
        <span className = "m_t"> First time?
        <Link to = '/signup'><button  className = "btn">Sign up</button></Link>
        </span>
        </div>
        

    </div>
    );
}
 
export default Login;