import React from "react";
import "./SignProject/Form.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Landing from "./SignProject/Landing";
import Signup from "./SignProject/sign/Signup";
import Login from "./SignProject/sign/Login";
import Forgot from "./SignProject/sign/Reset/Forgot";
import Reset from "./SignProject/sign/Reset/Reset";
// import Profile from './SignProject/Profile'
import Procopy from "./SignProject/procopy";
import NotFound from "./SignProject/NotFound";
import Verify from "./SignProject/Verify";
import PrivateRoute from "./SignProject/Utils/PrivateRoute";
import PublicRoute from "./SignProject/Utils/PublicRoutes";
// import { getUser} from './SignProject/Utils/Common';

const App = () => {
  // const usersocket =getUser()
  // if(usersocket){
  //     console.log(usersocket + 'is in');
  //   }else{
  //     console.log('logged out');
  //   }
  // console.log(getUser());
  return (
    <Router>
      {/* <Nav/> */}
      <Switch>
        <Route exact path="/">
          <Landing />
        </Route>
        <Route exact path="/signup">
          <Signup />
        </Route>
        <Route exact path="/verify">
          <Verify />
        </Route>
        <Route exact path="/user/forgot">
          <Forgot />
        </Route>
        <Route exact path="/user/reset/:id">
          <Reset />
        </Route>
        <PrivateRoute exact path="/profile" Component={Procopy} />
        <PublicRoute exact path="/login" Component={Login} />
        <Route component={NotFound} />
      </Switch>
    </Router>
  );
};

export default App;
