import React, { useEffect, useState } from "react";
import "./navMenu.css";
import { BsSearch } from "react-icons/bs";
import Message from "./message";
import Axios from "axios";
import { getToken } from "../../Utils/Common";
import { apiUrl } from "../../Utils/api";
import Navbar from "../navbar";
import { useDispatch, useSelector } from "react-redux";
import { useFetchUserQuery } from "../../features/reduxApi/api";
import { users, AllChats } from "../../features/thunkapi/msgs";
import { fetchMembers } from "../../features/thunkapi/msgHolder";

const NavMenu = (props) => {
  const dispatch = useDispatch();
  // const msgs = useSelector((state)=>state.chats.AllChats)
  const [userMsgs] = useSelector((state) => state.UserMsgs.msgs);
  const [members] = useSelector((state) => state.ChatMembers.group);

  const [allUsers, setAllUsers] = useState([]); //leave for search all users
  const [allData, setAllData] = useState(false); //leave for search is true or false
  const [value, setValue] = useState(""); //leave for the search value
  const [filteredData, setFilteredData] = useState([]);
  const [cleanFiltered, setCleanFiltered] = useState([]);
  const [finalOrder, setFinalOrder] = useState([]);

  let conIds = [];

  const token = getToken();
  const {
    data: user,
    error: userErr,
    isLoading: userLoading,
  } = useFetchUserQuery();

  useEffect(() => {
    dispatch(fetchMembers(user && user.authData._id));
  }, [user, userMsgs]);

  useEffect(() => {
    const url = apiUrl + "/getting/data";
    Axios.get(url, {
      withCredentials: true,
      headers: {
        Authorization: token,
      },
    })
      .then((response) => {
        setAllUsers(response.data);
      })
      .catch((error) => {
        console.log("Error:" + error);
      });
  }, []);

  useEffect(() => {
    members &&
      members.map((con) => {
        const getMessages = async () => {
          try {
            const res = await Axios.get(apiUrl + "/user/messages/" + con._id);
            if (res.data.length > 0) {
              await dispatch(AllChats(res.data));
            }
          } catch (err) {
            console.log(err);
          }
        };
        getMessages();
      });
  }, [members]);

  const handleSearchSubmit = (item) => {
    if (item !== "") {
      setAllData(true);
      let result = allUsers.filter((data) => {
        return Object.values(data)
          .join("")
          .toLowerCase()
          .includes(item.toLowerCase());
      });
      setFilteredData(result);
    } else {
      setAllData(false);
    }
  };

  const handleUpdateSeen = async () => {
    try {
      let TextId = user && user.authData._id;
      await Axios.put(apiUrl + "/user/messages/updateCount", { id: TextId });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    let redData = filteredData.filter((notOwn) => {
      return notOwn._id !== user.authData._id;
    });
    setCleanFiltered(redData);
  }, [filteredData, user && user.authData._id]);

  const handleCreateCon = (e) => {
    const conversation = {
      senderId: props.user._id,
      receiverId: e._id,
    };
    Axios.post(apiUrl + "/user/conversations", conversation).then((res) => {
      return dispatch(users({ showFriendMsgs: true, friendId: res.data }));
      // console.log(res.data);
    });
  };

  // let filterdHolder = [...orderedArr];

  useEffect(() => {
    if (members && members.length > 0) {
      let memSort = [...members];
      let orderdMem = memSort.sort((a, b) => {
        return a.updatedAt > b.updatedAt
          ? -1
          : a.updatedAt === b.updatedAt
          ? 0
          : 1;
      });
      setFinalOrder(orderdMem);
    }
  }, [members]);

  return (
    <div className="menuHolder">
      <div className="header">
        <form
          onChange={(e) => {
            e.preventDefault();
            return handleSearchSubmit(e.target.value);
          }}
        >
          <input
            type="text"
            placeholder="Search for Users"
            value={value}
            onChange={(event) => {
              setValue(event.target.value);
            }}
          />
          <div className="serachIcon" onClick={() => handleSearchSubmit(value)}>
            <BsSearch style={{ color: "#000", fontSize: "22px" }} />
          </div>
        </form>
        <div className="navbartab">
          <Navbar user={props.user} socket={props.socket} />
        </div>
      </div>
      <div className="UsersHolder">
        {/* <div> */}

        {allData ? (
          cleanFiltered.length > 0 ? (
            cleanFiltered.map((searchData, i) => {
              // console.log(searchData);
              if (searchData.isValid) {
                return (
                  <div
                    key={i}
                    onClick={() => {
                      handleCreateCon(searchData);
                      // setAllData(false)
                      setValue("");
                    }}
                  >
                    <Message
                      value={searchData}
                      currentUser={props.user}
                      data={allData}
                      online={props.online}
                    />
                  </div>
                );
              }
            })
          ) : (
            <Message
              value={false}
              currentUser={props.user}
              data={allData}
              online={props.online}
            />
          )
        ) : // <div>
        members && members.length === 0 ? (
          <div className="navOverlay">
            <p>Please search for a friend to start a chat</p>
          </div>
        ) : (
          <div>
            {finalOrder &&
              finalOrder.map((c) => {
                if (c.active) {
                  return (
                    <div key={c._id}>
                      <div
                        key={c._id}
                        onClick={() => {
                          handleUpdateSeen();
                          return dispatch(
                            users({
                              showFriendMsgs: true,
                              friendId: c,
                              groupId: c._id,
                            })
                          );
                        }}
                      >
                        <Message
                          conversation={c}
                          currentUser={props.user}
                          online={props.online}
                          data={allData}
                          orderData={finalOrder}
                        />
                      </div>
                    </div>
                  );
                }
              })}
          </div>
        )}
        {/* </div> */}

        {/* </div> */}
      </div>
    </div>
  );
};

export default NavMenu;
