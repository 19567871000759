import Axios from 'axios'
import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import {apiUrl} from '../../Utils/api'
import './reset.css'
const Reset = () => {
    const {id} = useParams()

    const history = useHistory()
    const [newPsd, setNewPsd] = useState('')
    const [psd2, setPsd2] = useState('')
    const [err, setErr] = useState('')
    const [loading, setLoading] = useState(false)

    const [emailRes, setEmailRes] = useState(false)

    const handleReset = (e)=>{
        e.preventDefault()
        setLoading(true)
        const data = {newPsd, psd2}

        Axios.post(apiUrl+'/user/reset/'+id,{data})
        .then(res=>{
            setLoading(false)
            setErr(res.data.message)
            setEmailRes(true)
            history.push(res.data.redirectUrl);
        }).catch(err=>{
            console.log(err);
        })
            
    }

    return ( 
        <div className = 'forgot'>
            <div className = "forgotform">
            {emailRes && <div className = 'msgerr'><p>{err}</p></div>}
                <form onSubmit = {handleReset}>
                    <input
                    type = 'password'
                    placeholder = 'New Password'
                    value = {newPsd}
                    required
                    onChange = {(e)=> setNewPsd(e.target.value)}
                    />
                    <input
                    type = 'password'
                    placeholder = 'Confirm Password'
                    value = {psd2}
                    required
                    onChange = {(e)=> setPsd2(e.target.value)}
                    />
                    {loading? <button className = 'disabled' disabled>Submit</button> :<button className = 'reset'>Submit</button> }
                    
                </form>
            </div>
        </div>
     );
}
 
export default Reset;