import React from "react"
import { removeUserSession} from '../Utils/Common';
import {apiUrl} from '../Utils/api'
import {useHistory } from 'react-router-dom';
import axios from 'axios'
const Logout = ({socket}) => {
    const history = useHistory()
    const handleLogout = async()=>{
        try{

           const res = await axios.get(apiUrl+'/logout', {withCredentials: true})
           socket.disconnect()
            removeUserSession();
            history.push(res.data.redirectUrl);
            // window.location.reload();
        }catch(err){
            console.log(err);
        }
        
    }
    return ( <div>
        <button className = 'logoutbtn' onClick = {handleLogout} >Logout</button>
    </div> );
}
 
export default Logout;