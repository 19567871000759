import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getToken } from "../../Utils/Common";

export const apiSlice = createApi({
  reducerPath: "chatApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://chatapp.websitedever.store",
    prepareHeaders(headers) {
      headers.set("Authorization", getToken());
      return headers;
    },
  }),
  endpoints: (builder) => {
    return {
      fetchUser: builder.query({
        query() {
          return `/profile`;
        },
      }),
    };
  },
});
export const { useFetchUserQuery } = apiSlice;
