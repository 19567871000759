import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
let initialState = {
  group: [],
  loading: false,
  error: "",
  // name:''
};

export const fetchMembers = createAsyncThunk(
  "user/chats",
  async (userId, { rejectWithValue }) => {
    // console.log(userId);
    try {
      let result = await fetch(
        `https://chatapp.websitedever.store/user/conversations/${userId}`
      ).then((res) => res.json());
      return result;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

export const apiSlice = createSlice({
  name: "getMembers",
  initialState,
  // reducers:{
  //     // showFriendgroup:true,friendId:c._id
  //     users(state,action){
  //         state.showFriendgroup=action.payload.showFriendgroup
  //         state.friendId= action.payload.friendId
  //         // state.name=action.payload
  //     }
  // },
  extraReducers: (builder) => {
    builder.addCase(fetchMembers.pending, (state, action) => {
      state.loading = true;
    });
    builder
      .addCase(fetchMembers.fulfilled, (state, action) => {
        state.group.length = 0;
        state.group.push(action.payload);
        state.loading = false;
      })
      .addCase(fetchMembers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        // action.payoad = state.group
      });
  },
  // }
});
// export const {users} = apiSlice.actions
export const ChatMembers = apiSlice.reducer;
// export const {users} = apiSlice.actions
