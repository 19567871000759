import Axios from 'axios';
import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import {apiUrl} from '../../Utils/api'
import './reset.css'
const Forgot = () => {

    const [emailHolder, setEmailHolder] = useState('')
    const [emailfail, setEmailFail] = useState(false)
    const [emailsuccess, setEmailSuccess] = useState(false)
    const [msg, setMsg] = useState('')

    const handleForget = (e)=>{
        e.preventDefault();
        Axios.post(apiUrl+'/user/forgot', {emailHolder})
        .then(res=>{
            if(res.data.success){
                setEmailSuccess(true)
                setMsg(res.data.msg)
            }else{
                setEmailFail(true)
                setMsg(res.data.msg)
            }
        })
    }

    return ( 
        <div className = 'forgot'>
            
            <div className = "forgotform">
                {(()=>{
                    if(emailfail){
                        return(
                            <div className = 'msgerr'><p>{msg}</p></div>
                        )
                    }else if(emailsuccess){
                        return(
                            <div className = 'msgsuc'><p>Suceess: {msg}</p></div>
                        )
                    }
                })()}
            <form onSubmit = {handleForget}>
                <input 
                type = 'text'
                value = {emailHolder}
                onChange = {(e)=>{
                    return setEmailHolder(e.target.value);
                }}
                placeholder = 'Email'
                />
                <div className = "bts">
                    <button className = 'reset' type = 'submit'>Reset</button>
                    <Link  to = '/login' className = 'backtoLogin'>Login</Link>
                </div>
                
            </form>
            
            
            </div>
        </div>
     );
}
 
export default Forgot;