import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
let initialState = {
  msgs: [],
  loading: false,
  error: "",
  // name:''
};
export const fetchFriendMsgs = createAsyncThunk(
  "user/msgs",
  async (friendId, { rejectWithValue }) => {
    // console.log(friendId);
    try {
      let result = await fetch(
        `https://chatapp.websitedever.store/user/messages/${friendId}`
      ).then((res) => res.json());
      return result;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

export const apiSlice = createSlice({
  name: "getMsgs",
  initialState,
  reducers: {
    // showFriendMsgs:true,friendId:c._id
    chatGroup(state, action) {
      state.messages = state.msgs;
      // state.name=action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchFriendMsgs.pending, (state, action) => {
      state.loading = true;
    });
    builder
      .addCase(fetchFriendMsgs.fulfilled, (state, action) => {
        state.msgs.length = 0;
        state.msgs.push(action.payload);
        state.loading = false;
      })
      .addCase(fetchFriendMsgs.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        // action.payoad = state.msgs
      });
  },
  // }
});
export const { chatGroup } = apiSlice.actions;
export const FriendMsgs = apiSlice.reducer;

// export const {users} = apiSlice.actions
