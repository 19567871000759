import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import Navbar from "./profile/navbar";
import "./profile/style.css";
import "./profile/webrtc.css";
import NavMenu from "./profile/navmenu/navMenu";
import ReadMessage from "./profile/MessageSection/readMessage";
import { BsSearch, BsReverseBackspaceReverse } from "react-icons/bs";
import Axios from "axios";
import { io } from "socket.io-client";
import { apiUrl } from "./Utils/api";
import { useFetchUserQuery } from "./features/reduxApi/api";
import { useDispatch, useSelector } from "react-redux";
import { fetchFriendMsgs } from "./features/thunkapi/chatMsgs";
import { users, ice, Caller } from "./features/thunkapi/msgs";
import ringtone from "./profile/calls/ringtone.mp3";
import videoImg from "./profile/calls/video.png";
import Peer from "simple-peer";

import SearchMsg from "./profileitems/searchMsg";
const Profile = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [showFriendMsgs] = useSelector((state) => state.UserMsgs.msgs); //group chats
  const chatGroup = useSelector((state) => state.chats.friendChat); //group to chat with
  const groupId = useSelector((state) => state.chats.groupId); //group to chat with
  const changeFriend = useSelector((state) => state.chats.showFriendMsgs); //member has changed
  const iceOffer = useSelector((state) => state.chats.ice);

  const searchMsg = useSelector((state) => state.msgSearch.filteredSearch);
  const searchnow = useSelector((state) => state.msgSearch.showSearch);
  // const recieverId = useSelector(state=>state.chats.reciever)
  const [user, setUser] = useState("");
  // const [myFriend, setMyFriend] = useState('')
  // const [myCharts, setMyCharts] = useState('')
  const [messages, setMessages] = useState([]);
  const [arrivalmsg, setArrivalmsg] = useState(null);
  const [members, setMembers] = useState([]);
  const [friendsmembers, setFriendsMembers] = useState("");
  //   const [searchMsg, setSearchMsg] = useState([]);
  //   const [searchnow, setSearchNow] = useState(false);
  const [mobile, setMobile] = useState(false);
  const [online, setOnline] = useState([]);
  const [nextSocket, setNextSocket] = useState("");
  // const [url, setUrl] = useState('')
  // const [file, setFile] = useState(false)
  const [cover, setCover] = useState(false);
  // const [count, setCount] = useState(0)
  const [chatMember, setChatMember] = useState("");
  // const [fileupload, setFileupload] = useState(false)
  // const [filesend, setFilesend] = useState(false)
  const [gotVideoCall, setGotVideoCall] = useState(false);
  const [disableVideo, setDisableVideo] = useState(false);
  const [videoPage, setVideoPage] = useState(false);
  const [callerIce, setCallerIce] = useState(null);
  const [callerName, setCallerName] = useState("");
  const [receiverId, setRecieverId] = useState(null);
  const [peerHolder, setPeerHolder] = useState(null);
  const [localVideo, setLocalVideo] = useState(null);
  // const [remoteVideo, setRemoteVideo] = useState(null)
  const [videoEnd, setVideoEnd] = useState(false);
  const [caller, setCaller] = useState(false);
  const [callee, setCallee] = useState(false);
  const videoSrcMe = useRef();
  const videoSrcOther = useRef();
  //apiUrl+ /
  //apiUrl+ /
  const socket = useRef();

  const { data, error, isLoading } = useFetchUserQuery();
  // const { data:memData, error:memError, isLoading:memIsLoading } = useFetchChatMembersQuery(data&&data.authData._id)

  useEffect(() => {
    socket.current = io(apiUrl + "");
    setNextSocket(socket.current);
    socket.current.on("getMsg", (data) => {
      setArrivalmsg({
        sender: data.senderId,
        text: data.text,
        thumbnail: data.thumbnail,
        name: data.name,
        type: data.type,
        file: data.file,
        _id: data.id,
        createdAt: Date.now(),
      });
    });
  }, []);

  useEffect(() => {
    arrivalmsg &&
      members.map((member) => member.mebers?.includes(arrivalmsg.sender)) &&
      setMessages((prev) => [...prev, arrivalmsg]);
  }, [arrivalmsg, members]);

  useEffect(() => {
    if (showFriendMsgs) {
      setMessages(showFriendMsgs);
    }
  }, [showFriendMsgs]);

  useEffect(() => {
    if (user._id) {
      socket.current.emit("addUser", user._id);
    }
    socket.current.on("getUsers", (users) => {
      setOnline(users);
    });
    //  return () => socket.current.close();
  }, [user, socket]);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        // console.log(data);
        return setUser(data.authData);
      } catch (err) {}
    };
    fetchUser();
  }, [data, user]);

  useEffect(() => {
    const friendId = () => {
      // console.log(myFriend);
      if (chatGroup) {
        let friend = chatGroup.members;
        if (user) {
          let man = friend && friend.filter((person) => person !== user._id);
          return setFriendsMembers(man.toString());
        }
      }
    };
    friendId();
  }, [chatGroup, user]);

  useEffect(() => {
    setCallerName(user.username);
  }, [callerName, user]);

  useEffect(() => {
    let controller = new AbortController();
    const getUser = async () => {
      try {
        if (friendsmembers) {
          const res = await Axios.get(apiUrl + "/user/" + friendsmembers, {
            signal: controller.signal,
          });
          setChatMember(res.data);
          controller = null;
        }
      } catch (err) {
        console.log(err);
      }
    };
    getUser();

    return () => controller?.abort();
  }, [friendsmembers]);

  useEffect(() => {
    let controller = new AbortController();
    const getConversations = async () => {
      try {
        let res = await Axios.get(apiUrl + "/user/conversations/" + user._id, {
          signal: controller.signal,
        });
        setMembers(res.data);
        controller = null;
      } catch (err) {
        console.log(err);
      }
    };
    getConversations();
    return () => controller?.abort();
  }, [user && user._id]);

  useEffect(() => {
    let controller = false;
    const getMessages = async () => {
      try {
        if (chatGroup) {
          await dispatch(fetchFriendMsgs(chatGroup._id));
          setCover(true);
          controller = true;
          // console.log(showFriendMsgs);
          // if(friendMsgs.payload.length >0){
          // let TextId= showFriendMsgs[showFriendMsgs.length-1]._id
          // await Axios.put(apiUrl+'/user/messages/updateCount',{id:TextId})
          //   controller = null;
          // }
        }
      } catch (err) {
        console.log(err);
      }
    };
    getMessages();
    return () => (controller = false);
  }, [changeFriend, showFriendMsgs]);

  // useEffect(() => {
  //   const updateCount = async () => {
  //     try {
  //       let TextId =
  //         showFriendMsgs && showFriendMsgs[showFriendMsgs.length - 1]._id;
  //       await Axios.put(apiUrl + "/user/messages/updateCount", { id: TextId });
  //     } catch (err) {
  //       console.log(err);
  //     }
  //   };
  //   updateCount();
  // }, [chatGroup]);

  const showSearchBox = () => {
    setMobile(!mobile);
  };

  const handleVideoCall = async (e) => {
    e.preventDefault();
    try {
      // dispatch(Caller({called:true}))
      setVideoPage(true);
      setCaller(true);
      let localStream = await navigator.mediaDevices.getUserMedia({
        video: true,
        audio: true,
      });
      setLocalVideo(localStream);
      videoSrcMe.current.srcObject = localStream;
      const peer1 = new Peer({
        initiator: true,
        trickle: false,
        stream: localStream,
      });
      peer1.on("signal", (data) => {
        socket.current.emit("videoCallICE1", {
          senderId: user._id,
          receiverId: friendsmembers,
          calleeName: callerName,
          ice: data,
        });
      });
      peer1.on("stream", (stream) => {
        videoSrcOther.current.srcObject = stream;
      });
      socket.current.on("videoCallICE2", (msg) => {
        if (msg) {
          // console.log(msg)
          peer1.signal(msg.ice);
        }
      });
      setPeerHolder(peer1);
      peer1.on("close", () => {
        console.log("peer closed");
        socket.current.off("videoCallICE2");
      });

      socket.current.on("videoEnded", (data) => {
        if (data.end) {
          peer1.on("close", () => {
            console.log("peer closed");
            socket.current.off("videoCallICE2");
          });
          // return handleVideohang()
          localStream.getTracks().forEach(function (track) {
            track.stop();
          });

          peer1.destroy();
          setVideoPage(false);
          setGotVideoCall(false);
        }
      });
      // history.push('/profile/videoCall')
    } catch (err) {
      console.log(err);
    }
  };

  const handleCallAccept = async () => {
    try {
      setVideoPage(true);
      setCallee(true);
      if (callerIce) {
        let localStream = await navigator.mediaDevices.getUserMedia({
          video: true,
          audio: true,
        });
        setLocalVideo(localStream);
        videoSrcMe.current.srcObject = localStream;
        // if(callerIce){
        const peer2 = await new Peer({
          initiator: true,
          trickle: false,
          stream: localStream,
        });
        peer2.on("signal", (data) => {
          socket.current.emit("videoCallICE2", {
            senderId: user._id,
            receiverId: receiverId,
            ice: data,
          });
        });
        peer2.on("stream", (stream) => {
          videoSrcOther.current.srcObject = stream;
        });

        socket.current.on("videoCallICE1", (msg) => {
          if (msg) {
            peer2.signal(msg.ice);
          }
        });
        setPeerHolder(peer2);
        peer2.on("close", () => {
          console.log("peer closed");
          socket.current.off("videoCallICE1");
        });
        socket.current.on("videoEnded", (data) => {
          if (data.end) {
            peer2.on("close", () => {
              console.log("peer closed");
              socket.current.off("videoCallICE1");
            });
            localStream.getTracks().forEach(function (track) {
              track.stop();
            });
            peer2.destroy();
            setCallerIce(null);

            setVideoPage(false);
            setGotVideoCall(false);
          }
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    socket.current.on("videoCallICE1", (msg) => {
      if (msg) {
        console.log(msg);
        setRecieverId(msg.senderId);
        setCallerIce(msg.ice);
        setGotVideoCall(true);
        setCallee(true);
        setCallerName(msg.calleeName);
      }
    });
  }, [callerIce]);

  const handleCallReject = () => {
    setGotVideoCall(false);
    socket.current.emit("videoEnded", {
      receiverId: receiverId,
      end: true,
    });
    // console.log('rejected');
  };
  const handleVideohang = async () => {
    try {
      localVideo.getTracks().forEach(function (track) {
        track.stop();
      });

      setVideoPage(false);

      if (peerHolder) {
        if (callee) {
          socket.current.emit("videoEnded", {
            receiverId: receiverId,
            end: true,
          });
          peerHolder.destroy();
        }
        if (caller) {
          socket.current.emit("videoEnded", {
            receiverId: friendsmembers,
            end: true,
          });
          peerHolder.destroy();
        }
      }

      setGotVideoCall(false);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      <div className="profilePage">
        <div className="NavBar">
          <Navbar user={user} socket={nextSocket} />
        </div>

        <div className={changeFriend ? "navMenuHide" : "navMenu"}>
          <NavMenu
            user={user}
            friend={chatGroup}
            online={online}
            socket={nextSocket}
            currentmsg={messages}
          />
        </div>

        <div className={changeFriend ? "messageSection" : "messageSectionHide"}>
          {videoPage ? (
            <div>
              <header className="navHeader flex videoheader">
                <div className="flex">
                  <button className="btn" onClick={handleVideohang}>
                    End
                  </button>
                </div>
              </header>
              <div className="videoPage flex">
                <video className="caller" ref={videoSrcMe} autoPlay />
                <video className="callee" ref={videoSrcOther} autoPlay />
              </div>
            </div>
          ) : (
            <div>
              {gotVideoCall ? (
                <div className="callPage">
                  <div className="vidoRing">
                    <h1>
                      <i>Video</i> call from <span>{callerName}</span>{" "}
                    </h1>
                    <audio src={ringtone} autoPlay loop></audio>
                    <button onClick={handleCallAccept}>Pick the call</button>
                    <button onClick={handleCallReject}>Reject</button>
                  </div>
                </div>
              ) : changeFriend ? (
                <div>
                  <div className="navHeader">
                    <div className="mainSearch">
                      <div className="boxSearch hideSearch">
                        {/* <h4 className="chatMember">{chatMember.username}</h4> */}
                        <SearchMsg messages={messages} />
                      </div>
                      {/* <div className="boxSearch hideSearch">
                        <form onChange={(e) => handleClicked(e.target.value)}>
                          <input
                            className="input"
                            type="text"
                            placeholder="Search For Old Messages"
                            onChange={(e) => setSearchHold(e.target.value)}
                          />
                        </form>
                        <div
                          className="searchIcon"
                          onClick={() => handleClicked(searchHold)}
                        >
                          <BsSearch
                            style={{ color: "#000", fontSize: "22px" }}
                          />
                        </div>
                      </div> */}
                      <div className="flex vidHeader">
                        <img
                          src={videoImg}
                          onClick={handleVideoCall}
                          alt="video img from https://www.flaticon.com"
                        />
                      </div>
                    </div>
                    {mobile ? (
                      <div className="MobileSearch">
                        <BsReverseBackspaceReverse onClick={showSearchBox} />
                      </div>
                    ) : (
                      <div className="MobileSearch">
                        <BsSearch onClick={showSearchBox} />
                      </div>
                    )}
                    <button
                      className="coverrem"
                      onClick={() => {
                        setCover(false);
                        dispatch(users({ showFriendMsgs: false }));
                      }}
                    >
                      <i className="arrow right"></i>
                    </button>
                  </div>

                  {mobile ? (
                    <div className="boxSearch">
                      <SearchMsg messages={messages} />
                    </div>
                  ) : (
                    <p></p>
                  )}

                  <div className="messagePage">
                    {!searchnow ? (
                      !cover ? (
                        <p>Loading ...</p>
                      ) : (
                        <div>
                          {showFriendMsgs && showFriendMsgs.length > 0 ? (
                            <div>
                              {messages.map((m, i) => (
                                <ReadMessage
                                  key={i}
                                  messages={m}
                                  own={m.sender === user._id}
                                  user={user}
                                  team={chatGroup}
                                  friend={friendsmembers}
                                  socket={nextSocket}
                                  online={online}
                                />
                              ))}
                            </div>
                          ) : (
                            <div>
                              <ReadMessage
                                user={user}
                                team={chatGroup}
                                friend={friendsmembers}
                                socket={nextSocket}
                                online={online}
                              />
                            </div>
                          )}
                        </div>
                      )
                    ) : (
                      <div>
                        {searchMsg.map((m, i) => (
                          <ReadMessage
                            key={i}
                            messages={m}
                            own={m.sender === user._id}
                            user={user}
                            team={chatGroup}
                            friend={friendsmembers}
                            socket={nextSocket}
                            online={online}
                          />
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <div className="blank">
                  <h1>Open a conversation to start a chart</h1>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Profile;
