import React, { useState } from 'react';
import axios from 'axios';
import Nav from '../Nav'
import { Link, useHistory } from 'react-router-dom';
import './sign.css';
import {apiUrl} from '../Utils/api'
const Signup = () => {
    const history = useHistory();

    const [name, setname] = useState('');
    const [email, setemail] = useState('');
    const [username, setusername] = useState('');
    const [password, setpassword] = useState('');
    const [psd2, setpsd2] = useState('');
    const [errors, setErrors] = useState([]);
    const [loading, setLoading] = useState(false);

    const handleSubmit = (e)=>{
        e.preventDefault()
        setLoading(true)
        const details ={name, email, username, password, psd2};

        axios.post(apiUrl+'/signup', {
        details
      })
      .then((response) =>{
        setLoading(false)
        if(response.data.errors){
                setErrors(response.data.errors); 
        }else{
           history.push(response.data.redirectUrl);
        } 
      }, (error) => {
        console.log(error);
      });
    }

    return ( 
        <div>
            <Nav/>
            <div className = "box">
        <div className = "center">
            <h1>sign up</h1>
            <span className = "underline"></span>
            <form onSubmit = {handleSubmit} className = "sign-box">
                {errors && <div>{errors.map((error, index)=>{
                    return(
                        <li key = {index} className= "errors">{error.msg}</li>
                    )
                })}</div>}
                <label>Name</label><br/>
                <input
                required
                type = "text"
                name = "name"
                value = {name}
                //  autocomplete="name"
                onChange = {(e)=>setname(e.target.value)}
                /><br/>
                <label>Email</label><br/>
                <input
                required
                type = "email"
                name = "email"
                value = {email}
                //  autocomplete="email"
                onChange = {(e)=>setemail(e.target.value)}
                /><br/>
                <label>Username</label><br/>
                <input
                required
                type = "text"
                name = "name"
                value = {username}
                 autoComplete="username"
                onChange = {(e)=>setusername(e.target.value)}
                /><br/>
                <label>Password</label><br/>
                <input
                required
                type = "password"
                name = "psd"
                value = {password}
                 autoComplete="new-password"
                onChange = {(e)=>setpassword(e.target.value)}
                /><br/>
                <label>Confirm Password</label><br/>
                <input
                required
                type = "password"
                name = "psd"
                value = {psd2}
                autoComplete="new-password"
                onChange = {(e)=>setpsd2(e.target.value)}
                /><br/>
                {loading? <button type = "submit" disabled className = 'btn'>Signing... </button>:<button className = 'btn' > Sign up</button>}
            </form>
            <span>Already have an account?
            <Link to = '/login'><button className = "btn login-btn">login</button></Link></span>
        </div>
        
    </div>
        </div>
    
    );
}
 
export default Signup;