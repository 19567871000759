import React, { useEffect, useState } from 'react'
import './style.css'
import Logout from '../sign/Logout'
import Axios from 'axios'

import {apiUrl} from '../Utils/api'
const Navbar = ({user,socket}) => {
    
    const url = 'https://media.defense.gov/2020/Oct/25/2002523049/-1/-1/0/201025-M-AB981-003.JPG'

    const [image, setImage] = useState(null)

    const [upload, setUpload] = useState(null)

    const[navtoggle, setNavtoggle] = useState(false)

    const [imgHolder, setImgHolder] = useState(false)


    useEffect(()=>{
        setImage(user.profilePicture)
       
       
        
    },[user._id])




    
        const previewFile = (e)=>{
            e.preventDefault()
            const file = e.target.files[0]
            setImgHolder(true)
            setUpload(file)
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = ()=>{
                setImage(reader.result);
          };
          
        }

    const handleChange = (e)=>{
        setNavtoggle(!navtoggle)
    }
    const handleOut = (e)=>{
        setNavtoggle(!navtoggle)
    }
  
    const handleUpload = async (e)=>{
        e.preventDefault()
        const data = new FormData()
        data.append('profilePic', upload)
        try{
            const res = await Axios.put(apiUrl+"/user/signup/"+ user._id, data,{ withCredentials: true})
            setImage(res.data)
            setNavtoggle(!navtoggle)
        }catch(err){
            console.log(err);
        }
        
    }

    // console.log(image);
    return (
            <div className="navbars">
                <div className="container" 
                id= {navtoggle? 'handleoutS':'handleinS'}
                >
                    <div className = "imagePal">
                        <object className = 'imgis' data={!imgHolder?user.profilePicture !== ""? image : url:image}>
                            <img className = 'imgis' crossOrigin="anonymous" src={!imgHolder?user.profilePicture !== ""? image : url:image} alt = "profile pic"/>
                        </object>
                    </div>
                    
                    <h4>{user.username}</h4>
                    
                    <div className="drop" onClick = {handleChange}>
                        <p><i className="arrow down"></i></p>
                    </div>
                </div>
                <div className = 'showcase'
                id= {navtoggle? 'handleinL':'handleoutL'}
                >
                    <div className="hidden">
                        
                        <div className="profilepic">
                            <input type='file'
                            id="filePic"
                            name = 'profilePic' 
                            className='visually-hidden '
                            onChange={previewFile}
                            />
                            <label htmlFor='filePic' className='lebelPic'>
                                {/* <object className = 'imgPic' data={ user.profilePicture !== "" ? image : url} type="image/jpg"> */}
                                    <img className = 'imgPicimg' alt = 'profile pic'
                                        src={imgHolder?image:url}
                                    />
                                {/* </object> */}
                            </label>
                            <button className = 'upload' type = 'submit' onClick = {(e)=>handleUpload(e)}>Upload</button>
                        </div>
                        <div className = 'borderbtw'>
                        </div>
                        <div className = 'logoutdiv'>
                            <h4 className='userMobile'>{user.username}</h4>
                            <Logout socket={socket}/>
                        </div>
                        <div onClick = {handleOut} className="dropup" >
                            <p className = 'out'><i className="arrow up"></i></p>
                        </div>
                    </div>
                </div>
                
            </div>
     );
}
 
 
export default Navbar;