import React, {Fragment} from 'react'
// import axios from 'axios'
import {Link} from 'react-router-dom'
import { getToken} from './Utils/Common';
import { AiFillWechat } from "react-icons/ai";
// import Logout from './sign/Logout'

function Navs(){
    const guestLinks = (
        <Fragment>
            <Link to = '/'>Home</Link>
            <Link to = "/login">Login</Link>
            <Link to  = "/signup">SignUp</Link>
        </Fragment>
    );
    const privateLinks = (
        <Fragment>
            {/* <Link to  = "/profile">Profile</Link> */}
            <Link to  = {'/profile'}>Profile</Link>
        </Fragment>
    )
    // const name = getToken()
    // console.log(name);
    // const good = true;
    return(
        <div className = 'navbar'>
            <nav className = 'navLinks'>
                {getToken() ? privateLinks : guestLinks } 
            </nav>
            <header className = 'imgHolder'>
                <AiFillWechat />
            </header>
        </div>
        
    )
}
export default Navs