import {createSlice} from '@reduxjs/toolkit'
let initialState ={
    AllChats:[],
    loading:false,
    error:'',
    // name:''
  };
  
export const apiSlice = createSlice({
    name:'apiCall',
    initialState,
    reducers:{
        // showFriendMsgs:true,friendId:c._id
        users(state,action){
            state.showFriendMsgs=action.payload.showFriendMsgs
            state.friendChat= action.payload.friendId
            state.groupId= action.payload.groupId
            // state.name=action.payload
        },
        AllChats(state,action){
            state.AllChats.push(action.payload)
        },
        ice(state,action){
            state.ice = action.payload.ice
            state.senderIce = action.payload.senderIce
            state.reciever = action.payload.reciever
        },
        Caller(state,action){
            state.caller = action.payload.called
        }
    },
})

export const msgReducer = apiSlice.reducer
export const {users,AllChats,ice,Caller} = apiSlice.actions