import React, { useState, useEffect, useRef} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import {format} from 'timeago.js'
import Axios from 'axios'
import { VscFilePdf } from "react-icons/vsc";
import './messageSection.css'
import { MdDoNotDisturbAlt,MdOutlineTextSnippet } from "react-icons/md";
import { SiMicrosoftexcel } from "react-icons/si";
import { AiTwotoneFileText } from "react-icons/ai";
import { FaFilePowerpoint,FaFileWord } from "react-icons/fa";
import {BsUpload } from "react-icons/bs";
import {apiUrl} from '../../Utils/api'
// import { ChatFriends } from '../../features/thunkapi/msgs';
import { chatGroup, fetchFriendMsgs } from '../../features/thunkapi/chatMsgs';
// import { Messages } from '../../features/thunkapi/msgHolder';


const ReadMessage = ({messages, own,user,friend,socket,team,online}) => {
  const dispatch = useDispatch()
  const [showFriendMsgs] = useSelector(state=>state.UserMsgs.msgs)
  // const [FriendMessages] =useSelector(state=>state.MsgHolder.msgs)

  const [togg, setTogg] = useState(false)
  const [delmsgAll, setDelmsgAll] = useState(false)
  const [delmsgOne, setDelmsgOne] = useState(false)
  const [msgs, setMsgs] = useState([messages])
  // const [link, setLink] = useState('')
  const [myCharts, setMyCharts] = useState('')
  const [url, setUrl] = useState('')
  const [file, setFile] = useState(false)
  const [fileupload, setFileupload] = useState(false)
  const [filesend, setFilesend] = useState(false)
  const [count, setCount] = useState(0)
  
  let scrollRef = useRef();

  useEffect(() => {
    scrollRef.current.scrollIntoView({ behavior: 'smooth' });
  },[]);
    useEffect(()=>{
      socket.on("getdelMsg", data=>{
        // console.log(data);
      if(data.Id===messages._id){
        setDelmsgAll(true)
        messages.text = data.text
        messages.updatedAt=Date.now()
      }else{
        return ;
        // setDelmsgOne(true)
      }
      })
    },[messages&&messages.text,messages,socket])

      const showdelMsg = ()=>{
        setTogg(!togg)
      }
    const delMsg = async()=>{
        const msgId = {
          Id: messages._id,
          driveId: messages.imgId
        }
        const TextId = {
          Id: messages._id
        }
        if(user._id === messages.sender){
          setDelmsgAll(true)
          socket.emit("delMsg",{
            senderId:messages.sender,
            receiverId:friend,
            text: 'this message is deleted!',
            Id:messages._id,
          })
          
        }else{
          setDelmsgOne(true)
          // socket.emit("delMsg",{
          //   senderId:messages.sender,
          //   receiverId:friend,
          //   Id:messages._id,
          // })
          // console.log(messages);
          // console.log('not yours');
        }
        try{
          if(user._id === messages.sender){
            if(messages.file){
              await Axios.put(apiUrl+'/user/messages/deleteall',msgId)
            }else{
              await Axios.put(apiUrl+'/user/messages/deleteall',TextId)
            }
            
          }else{
            Axios.put(apiUrl+'/user/messages/deleteOne',TextId)
            // console.log(res.data);
          }
          setTogg(!togg);
        }catch(err){
          console.log(err);
        }
      }

      useEffect(()=>{
        // console.log(messages);
        // if(messages.text && messages.text.substring(0,32) === 'https://drive.google.com/file/d/'){
        //   let url =messages.text.substring(0,65)+'/preview'
        //   setLink(url)
        // }
        // console.log(link);
        // if(messages.file){
          // console.log(messages);
        // }
        if(showFriendMsgs&&showFriendMsgs.length>0){
          let friendCount = showFriendMsgs[showFriendMsgs.length-1].count;
          setCount(friendCount);
        }
        

        
        
        
      },[showFriendMsgs,count])

    //   useEffect(()=>{
    //     console.log(count);
    // },[count])

    //   useEffect(()=>{
    //     let perOnline = online.find(m => m.userId === friend)
    //     console.log(perOnline.userId);
    //         // setRoom(perOnline)
    // },[online,friend])
      // form handle 
      const handleTextSubmit = async (e)=>{
        e.preventDefault()
        let counter = count+1
          setCount(counter)
          let message = {
            sender : user._id,
            text: myCharts,
            conversationId : team._id,
            count:counter
          }
          console.log(counter);
        sendMsg(message)
      }

    const sendMsg = async(message)=>{
    
      if(file){
        message.text =url.url
        message.imgId =url.imgId
        message.Type =url.type
        message.file =url.file
        message.thumbnail = url.thumbnail
        message.name = url.name
      }
      try{
        if(message.text === ''){
          return null;
        }else{
          const res = await Axios.post(apiUrl+'/user/messages', message);
          if(messages === []){
            // setMsgs([res.data])
            console.log(res.data);
            dispatch(fetchFriendMsgs(res.data.conversationId))
            dispatch(chatGroup())
            
          }else{
            // console.log(msgs);
            // setMsgs([...msgs, res.data])
            // console.log(res.data);
            // console.log(FriendMessages[0]);
            // let msgData = await dispatch(ChatFriends([res.data]))
            dispatch(fetchFriendMsgs(res.data.conversationId))
            dispatch(chatGroup())
            // let msgData = [...FriendMessages[0],res.data]
            // dispatch(Messages(msgData))
            // console.log(msgData);
            // console.log();
          }
          if(file){
            socket.emit("sendMsg",{
            senderId:user._id,
            receiverId: friend,
            text: url.url,
            thumbnail: url.thumbnail,
            name: url.name,
            type:url.type,
            file:url.file,
            id: res.data._id
          })
          }else{
            socket.emit("sendMsg",{
              senderId:user._id,
              receiverId: friend,
              text: myCharts,
              id: res.data._id
            })
          }
          
          setMyCharts('')
          setFile(false)
          setUrl('')
          setFilesend(false)
        }
        
      }catch(err){
        console.log(err);
      }
    }

      const fileChange = async(files)=>{
        setFileupload(true)
        let formData = new FormData();
        const config = {
            header: { 'content-type': 'multipart/form-data'}
        }
        formData.append("file", files[0])
      try{
        const res =await Axios.post(apiUrl+'/user/messages/uploadfiles',formData,config)
        setUrl(res.data)
        // console.log(res.data);
        setFileupload(false)
        setFilesend(true)
        setFile(true)
      }catch(err){
        console.log(err);
      }
      }
      
    return ( 
      <div ref={scrollRef}>
        <div className = 'Readmessage '>
          {(()=>{
            if(messages){
            if(messages.delAll||delmsgAll){
              return(
                <div className = {own? 'TopMessage own': 'TopMessage'}>
              {messages.sender !== user._id?
              <div className = 'delText'>
                    <div>
                      <p><MdDoNotDisturbAlt className = 'delIcon'/>{messages.text = 'This msg is deleted!'}</p>
                      <i className = 'timmer'>{format(messages.updatedAt)}</i>
                    </div>
                </div> 
                :
                <div style= {{display:'none', opacity:'0'}}>
                </div> 
            }
                
            </div>
              )
            }else if(messages.delOne|| delmsgOne){
              // user._id === messages.sender
              return(
                <div className = {own? 'TopMessage own': 'TopMessage'}>
                {messages.sender !== user._id?
                <div className = 'delText'>
                      <div>
                        <p className = "ownDel"><MdDoNotDisturbAlt className = 'delIcon'/>{messages.text = 'You deleted this msg!'}</p>
                        <i className = 'timmer'>{format(messages.updatedAt)}</i>
                      </div>
                  </div> 
                  :
                  <div className = 'spanText'>
                    <div className = {togg?'delEl show':'delEl'}>
                      {own?
                        <button className = 'delElbtn' onClick = {delMsg}>Delete for Everyone</button>
                      :
                        <button className = 'delElbtn' onClick = {delMsg}>Delete for me</button>
                      }
                    
                  </div>
                          <p>{messages.text}</p>
                          <i className = 'timmer'>{format(messages.createdAt)}</i>
                          <span className = 'dropper' onClick = {showdelMsg}><i className="arrow down"></i></span>
                  </div> 
              }
                  
              </div>
              )
             
            }else{
              return(
                <div className = {own? 'TopMessage own': 'TopMessage'}>
                <div className = 'spanText'>
                  <div className = {togg?'delEl show':'delEl'}>
                  {own?
                        <button className = 'delElbtn' onClick = {delMsg}>Delete for Everyone</button>
                      :
                        <button className = 'delElbtn' onClick = {delMsg}>Delete for me</button>
                  }
                  </div>
                  <div>
                  {(() => {
                    if(messages && messages.file){
                    //   console.log(messages);
                      if(messages.Type === "video/mp4"||messages.Type === "video/mpeg"||messages.Type === "video/quicktime"||messages.Type === "video/x-la-asf"||messages.Type === "video/x-ms-asf"||messages.Type === "video/x-msvideo"||messages.Type === "video/x-sgi-movie"){
                        // console.log('is a video');
                          // let url =messages.text.substring(0,65)+'/preview'
                        return(
                          <div>
                            <a href ={messages.text} download style= {{textDecoration:"none"}} className='download'>  
                              <video src={messages.text} className = "imgOverlay" controls/>
                              <div >
                                <p style= {{fontSize:'16px', color: "#fff"}}>
                                {messages.name.length>30?messages.name.substring(0,28)+' ...'+messages.name.substring(messages.name.length-4,messages.name.length):messages.name}
                                </p>
                              </div>
                            </a>
                          </div>
                        )
                      }else if(messages.Type && messages.Type === "application/pdf"){
                          return(
                            <div>
                            <a href ={messages.text} download className='download'>  
                            <img className='pdfHolder' src={messages.thumbnail} alt="pdfHolder" />
                            <div className='pdfOverlay'>
                              <div>
                                <VscFilePdf className='pdfIcon'/>
                              </div>
                            
                              <p style= {{fontSize:'16px', color: "#fff"}}>
                                    {messages.name.length>30?messages.name.substring(0,28)+' ...'+messages.name.substring(messages.name.length-4,messages.name.length):messages.name}</p>
                            </div>
                            </a>
                            </div>
                          )
                        }else if(messages.Type ===  "image/jpeg"||messages.Type === "image/bmp" ||messages.Type === "image/gif" || messages.Type === "image/pipeg" || messages.Type === "image/tiff"||messages.Type === "image/webp"||messages.Type ===  "image/png"){
                          return(
                            <div>
                              <a href ={messages.text} download style= {{textDecoration:"none"}} className='download'>  
                                <img src={messages.text} alt="imgHolder" className = "imgOverlay" />
                                <div >
                                  <p style= {{fontSize:'16px', color: "#fff"}}>
                                    {/* {if()} */}
                                    {messages.name.length>30?messages.name.substring(0,28)+' ...'+messages.name.substring(messages.name.length-4,messages.name.length):messages.name}</p>
                                </div>
                              </a>
                            </div>
                          )}else if(messages.Type ===  "audio/mpeg"||messages.Type === "audio/opus" ||messages.Type === "audio/ogg" || messages.Type === "audio/x-aiff" || messages.Type === "audio/x-wav"||messages.Type === "audio/m4a"||messages.Type ===  "audio/aac"){
                          return(
                            <div>
                              <a className='download' href ={messages.text} download style= {{textDecoration:"none"}}>  
                              <audio src={messages.text} controls>
                              </audio>
                                <div >
                                  <p style= {{fontSize:'16px', color: "#fff"}}>
                                    {/* {if()} */}
                                    {messages.name.length>30?messages.name.substring(0,28)+' ...'+messages.name.substring(messages.name.length-4,messages.name.length):messages.name}</p>
                                </div>
                              </a>
                            </div>
                          )
                        }else if(messages.Type ===  "application/msword"||messages.Type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document"){
                          return(
                            <div>
                              <a className='download' href ={messages.text} download style= {{textDecoration:"none"}}>  

                              <img className='pdfHolder' src={messages.thumbnail} alt="application docs" />
                            <div className='pdfOverlay'>
                              <div>
                                <FaFileWord className='appIcon'/>
                              </div>
                            
                              <p style= {{fontSize:'16px', color: "#fff"}}>
                                    {messages.name.length>30?messages.name.substring(0,28)+' ...'+messages.name.substring(messages.name.length-4,messages.name.length):messages.name}</p>
                            </div>
                            </a>
                            </div>)
                        }else if(messages.Type === "application/vnd.oasis.opendocument.text"){
                          return(
                            <div>
                              <a className='download' href ={messages.text} download style= {{textDecoration:"none"}}>  
                              
                              <img className='pdfHolder' src={messages.thumbnail} alt="application docs" />
                            <div className='pdfOverlay'>
                              <div>
                              <MdOutlineTextSnippet className='appIcon'/>
                              </div>
                              <p style= {{fontSize:'16px', color: "#fff"}}>
                                    {messages.name.length>30?messages.name.substring(0,28)+' ...'+messages.name.substring(messages.name.length-4,messages.name.length):messages.name}</p>
                            </div>

                              </a>
                            </div>)
                        }else if(messages.Type === "application/vnd.ms-powerpoint"||messages.Type ===  "application/vnd.openxmlformats-officedocument.presentationml.presentation"||messages.Type === "application/vnd.oasis.opendocument.presentation"){
                          return(
                            <div>
                              <a className='download' href ={messages.text} download style= {{textDecoration:"none"}}>
                              <img className='pdfHolder' src={messages.thumbnail} alt="application docs" />
                            <div className='pdfOverlay'>
                              <div>
                              <FaFilePowerpoint className='appIcon'/>
                              </div>
                              <p style= {{fontSize:'16px', color: "#fff"}}>
                                    {messages.name.length>30?messages.name.substring(0,28)+' ...'+messages.name.substring(messages.name.length-4,messages.name.length):messages.name}</p>
                            </div>
                              </a>
                            </div>
                          )}else if(messages.Type === "application/vnd.ms-excel"||messages.Type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"|| messages.Type === "application/vnd.oasis.opendocument.spreadsheet"){
                          return(
                            <div>
                              <a className='download' href ={messages.text} download style= {{textDecoration:"none"}}>  
                              <img className='pdfHolder' src={messages.thumbnail} alt="application docs" />
                            <div className='pdfOverlay'>
                              <div>
                              <SiMicrosoftexcel className='appIcon'/>
                              </div>
                              <p style= {{fontSize:'16px', color: "#fff"}}>
                                    {messages.name.length>30?messages.name.substring(0,28)+' ...'+messages.name.substring(messages.name.length-4,messages.name.length):messages.name}</p>
                            </div>

                              </a>
                            </div>
                          )
                    }else{
                      return(
                        <div>
                          <a className='download' href ={messages.text} download style= {{textDecoration:"none"}}>  
                          <img className='pdfHolder' src={messages.thumbnail} alt="application docs" />
                        <div className='pdfOverlay'>
                          <div>
                          <AiTwotoneFileText className='pdfIcon'/>
                          </div>
                          <p style= {{fontSize:'16px', color: "#fff"}}>
                                {messages.name.length>30?messages.name.substring(0,28)+' ...'+messages.name.substring(messages.name.length-4,messages.name.length):messages.name}</p>
                        </div>

                          </a>
                        </div>
                      )
                    }
                         

                    //end of my new data


                      }else{
                        return(
                        <div>
                          {/* {own? <p>You</p>:<p>{chatMember}</p>} */}
                          <p>{messages.text}</p>
                          <i className = 'timmer'>{format(messages.createdAt)}</i>
                          <span className = 'dropper' onClick = {showdelMsg}><i className="arrow down"></i></span>
                        </div>
                        )
                      }
                    })()}
                     
                  </div>
                  <i className = 'timmer'>{format(messages.createdAt)}</i>
                  <span className = 'dropper' onClick = {showdelMsg}><i className="arrow down"></i></span>
                </div> 
                
            </div>
          )
            }
          }})()}



          {/* {messages.delAll||delmsgAll?
            <div className = {own? 'TopMessage own': 'TopMessage'}>
              {messages.sender !== user._id?
              <div className = 'delText'>
                    <div>
                      <p><MdDoNotDisturbAlt className = 'delIcon'/>{messages.text = 'This msg is deleted!'}</p>
                      <i className = 'timmer'>{format(messages.updatedAt)}</i>
                    </div>
                </div> 
                :
                <div style= {{display:'none', opacity:'0'}}>
                  <p>this is deleted</p>
                </div> 
            }
                
            </div>
            :
            
            } */}
        </div>
        {/* input section */}
        <div className = 'inputMessage'>
          {
            !fileupload?
          
            !filesend?
          <form className = 'formInput' onSubmit = {handleTextSubmit}>
            <input type = 'text' value = {myCharts} onChange = {(e)=>{
              setMyCharts(e.target.value)
              }}
              placeholder = 'Type a Message'
            />
            
              <div className='formbtns'>
              {/* <div className ='filedata'>
                  <input type="file" className = 'inputfile' id = "fileHandle" onChange={(e)=>fileChange(e.target.files)}/>
                  <label className = 'inputfile label' htmlFor="fileHandle"></label>
                  <BsUpload className = 'inputfile'/>
              </div> */}
              <button className= 'textBtn'>Send</button>
            </div>
           
             
            
          </form>
          :
          <form className = 'btnForm' onSubmit = {handleTextSubmit}>
              <button className= 'textBtnaln'>Send</button>
          </form>
          :
          <div>
            <div class="loader">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
          }
        </div>
      </div>
     );
}
 
export default ReadMessage;