import React, { useEffect, useState, useRef } from "react";
import { BsSearch, BsReverseBackspaceReverse } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";

import { search } from "../features/thunkapi/search";

const SearchForm = ({ messages }) => {
  const [searchHold, setSearchHold] = useState("");
  const [searchMsg, setSearchMsg] = useState([]);
  const [searchnow, setSearchNow] = useState(false);

  const dispatch = useDispatch();
  // const history = useHistory();

  const handleClicked = (item) => {
    let searchData = item && item.toLowerCase();
    if (searchHold !== "") {
      console.log(messages);
      let filResult = messages.filter((dataMsg) => {
        return Object.values(dataMsg)
          .join("")
          .toLowerCase()
          .includes(searchData);
      });
      dispatch(search({ filteredSearch: filResult, showSearch: true }));
      setSearchMsg(filResult);
      setSearchNow(true);
    } else {
      setSearchNow(false);
    }
  };
  return (
    <div className="boxSearch boxSearchInner">
      <form onChange={(e) => handleClicked(e.target.value)}>
        <input
          className="input"
          type="text"
          placeholder="Search For Old Messages"
          onChange={(e) => setSearchHold(e.target.value)}
        />
      </form>
      <div className="searchIcon" onClick={() => handleClicked(searchHold)}>
        <BsSearch style={{ color: "#000", fontSize: "22px" }} />
      </div>
    </div>
  );
};

export default SearchForm;
