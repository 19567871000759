import { configureStore } from "@reduxjs/toolkit";
// import { useDispatch } from 'react-redux'
import { setupListeners } from "@reduxjs/toolkit/query";
import { apiSlice } from "../SignProject/features/reduxApi/api";
import { msgReducer } from "../SignProject/features/thunkapi/msgs";
import { SearchReducer } from "../SignProject/features/thunkapi/search";
import { FriendMsgs } from "../SignProject/features/thunkapi/chatMsgs";
import { ChatMembers } from "../SignProject/features/thunkapi/msgHolder";

export const store = configureStore({
  reducer: {
    // user:apiReducer
    [apiSlice.reducerPath]: apiSlice.reducer,
    chats: msgReducer,
    UserMsgs: FriendMsgs,
    ChatMembers: ChatMembers,
    msgSearch: SearchReducer,
    // MsgHolder:MsgHolder
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      // immutableCheck: { warnAfter: 128 },
      // serializableCheck: { warnAfter: 128 },
      immutableCheck: false,
      serializableCheck: false,
    }),
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(apiSlice.middleware)
  // middleware: (getDefaultMiddleware)=>{
  //     return getDefaultMiddleware().concat(apiSlice.middleware)
  // }
});
setupListeners(store.dispatch);
