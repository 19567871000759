import React from 'react'
const Verify = () => {
    const style = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width:'100vw',
        height: '100vh',
        color: '#f4f4f4',
        fontWeight: 'bold'
    }
    return ( 
        <div style = {style}>
            <h1>Thanks for the sign up. Please open your email to verify your account. </h1>
        </div>
     );
}
 
export default Verify;