import React from 'react'
import { Redirect, Route } from 'react-router-dom';
import { getToken } from './Common';
const PrivateRoute = ({Component, ...rest }) => {
    let token = getToken()
    return ( 
        
        <Route 
        {...rest}
        render = {(props)=>{
            return(
                token ? <Component {...props} /> : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
            )
        }}
        
        />
     );
}
 
export default PrivateRoute;