import { createSlice } from "@reduxjs/toolkit";
let initialState = {
  filteredSearch: [],
  loading: false,
  error: "",
  showSearch: false,
  // name:''
};

export const apiSlice = createSlice({
  name: "apiCall",
  initialState,
  reducers: {
    // showFriendMsgs:true,friendId:c._id
    search(state, action) {
      state.filteredSearch = action.payload.filteredSearch;
      state.showSearch = action.payload.showSearch;
      // state.name=action.payload
    },
  },
});

export const SearchReducer = apiSlice.reducer;
export const { search } = apiSlice.actions;
